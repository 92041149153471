import {PlusOutlined} from '@ant-design/icons';
import {Button, Col, ColProps, Divider, Form, Row} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {ModalFormFieldTypes} from 'components/CRUDTable/types';
import InnerTableProvider from 'context/InnerTable';
import moment from 'moment';
import {DownloadSimple, Trash} from 'phosphor-react';
import {useRef, useState} from 'react';

import './styles.less';
import {t} from 'i18next';
import CaseInput from '../CaseInput';
import HELPER_FUNCS from 'utils/helpers/funcs';

const defaultColProps = {
	xs: 24,
	lg: 8
};

const required = [{required: true}];

interface FormTitleTypes {
	label?: string;
	colProps: ColProps;
	field?: ModalFormFieldTypes;
	formFromBack?: any;
}

function calcFieldRules(isRequired?: boolean, rules?: any) {
	const newRules = isRequired === false ? rules : [...(rules || []), ...required];

	return newRules;
}

function ltrim(str: any) {
	if (!str) return str;
	return str.replace(/^\s+/g, '');
}

const FormTitle = ({label, colProps, formFromBack, field}: FormTitleTypes) => {
	let hidden = false;
	if (typeof field?.props?.hidden === 'function') {
		hidden = field.props.hidden(formFromBack);
	} else if (field?.props?.hidden) {
		hidden = field.props.hidden;
	}
	return (
		<Col {...colProps} hidden={hidden}>
			<div className="form-group-title">{label}</div>
		</Col>
	);
};

interface FormItemProps {
	name?: string | (string | number)[];
	field: ModalFormFieldTypes;
	formErrors: any;
	parentName?: string;
	form?: FormInstance;
	data?: any;
	index?: number;
	disabled?: boolean;
	dynemicLabel?: string;
	selectedRowId?: number;
	visible?: 'ADD' | 'EDIT' | 'VIEW';
	formItemCustomInfo?: any;
	formFromBack?: any;
	updateTable?: () => void;
	forceSetStateForCRUDFormItem?: (values: any) => void;
	// USED FOR MODAL FORM TABS PERMISSIONS
	permissions?: any;
	menuId?: number;
}

const FormItem = ({
	name,
	field,
	formErrors,
	parentName,
	index = 0,
	disabled,
	form,
	data,
	dynemicLabel,
	selectedRowId,
	visible,
	formItemCustomInfo,
	formFromBack,
	updateTable,
	forceSetStateForCRUDFormItem,
	permissions,
	menuId
}: FormItemProps) => {
	if (field.type === 'divider') {
		return (
			<Col xs={24}>
				<Divider style={{margin: '0 0 15px'}} />
			</Col>
		);
	}

	if (field.type === 'title') {
		return (
			<FormTitle
				label={field.label}
				colProps={field.colProps ? field.colProps : {xs: 24}}
				formFromBack={formFromBack}
				field={field}
			/>
		);
	}

	if (field.type === 'button') {
		const [loading, setLoading] = useState<number>(0);
		let hidden = false;
		if (typeof field?.props?.hidden === 'function') {
			hidden = field.props.hidden(formFromBack);
		} else if (field?.props?.hidden) {
			hidden = field.props.hidden;
		}
		return (
			<Col {...field.colProps} hidden={hidden}>
				<Button
					type="primary"
					size="large"
					href={field?.href || undefined}
					download={field?.href ? true : false}
					className={field?.className || ''}
					loading={loading === field.id}
					disabled={loading === field.id || field.disabledAt?.includes('ADD')}
					icon={field?.icon ? field.icon : <DownloadSimple color="white" size={24} weight="fill" />}
					onClick={() => {
						setLoading(field?.id || 0);
						if (field?.onClick) {
							field?.onClick(selectedRowId, formFromBack).finally(() => {
								setLoading(0);
							});
						}
					}}>
					{field.label ? field.label : ''}
				</Button>
			</Col>
		);
	}

	if (field.type === 'newLine') {
		return <Col xs={24} />;
	}
	if (field.type === 'newLine_height35') {
		return <Col xs={24} style={{height: 35}} />;
	}

	const {
		depFieldKey,
		depValue,
		depOptionsKey,
		depOptions,
		fetchOnDepValue,
		colProps = defaultColProps,
		formItemProps,
		isRequired,
		isRequiredDep,
		label,
		isRequiredAll,
		disabledAt,
		isDisabled,
		disabledOnDep,
		tooltip,
		...props
	} = field;

	if (field.type === 'CRUDForm') {
		return (
			<Form.Item noStyle shouldUpdate={() => true}>
				{({setFieldsValue}) => {
					return (
						<Col {...colProps}>
							{field?.CRUDComponent?.({
								form,
								setFieldsValue,
								name,
								parentName,
								disabled,
								dynemicLabel,
								formErrors,
								forceSetStateForCRUDFormItem
							})}
						</Col>
					);
				}}
			</Form.Item>
		);
	}

	let newFormItems = formItemProps;

	if (field.type === 'text' || field.type === 'textArea') {
		newFormItems = {
			...newFormItems,
			getValueFromEvent: (val: any) => {
				const simbols = '~!@#$%^&*()_+=<>?,./\\{}[]:";-№՝|`₹₽₸₴€₪¥圓£ƒ₤ł';
				let startVal = val.target.value;
				if (val.target.value.length === 1 && simbols.includes(startVal)) {
					startVal = '';
				}
				return ltrim(startVal);
			}
		};
	} else if (field.type === 'file') {
		newFormItems = {
			...newFormItems,
			getValueFromEvent: (val: any) =>
				typeof val === 'string' ? val : val?.fileList?.length ? val.file : undefined
		};
	} else if (field.type === 'date') {
		newFormItems = {
			...newFormItems,
			getValueFromEvent: (val: any) => {
				if (!val) return null;

				if (props.datePickerProps?.format === 'YYYY') {
					return moment(val).format('YYYY');
				} else if (props.datePickerProps?.format === 'HH:mm') {
					return moment(val, 'HH:mm').format('HH:mm');
				} else if (props.datePickerProps?.format === 'DD-MM-YYYY HH:mm') {
					return moment(val).format('YYYY-MM-DD HH:mm');
				} else {
					return moment(val).format('YYYY-MM-DD');
				}
			}
		};
	}

	let newLabel = dynemicLabel || label;

	if (tooltip && formItemCustomInfo && formItemCustomInfo.length !== 0) {
		newLabel = tooltip(formItemCustomInfo, newLabel);
	}

	const helpStatus =
		name &&
		(Array.isArray(name)
			? parentName &&
			  formErrors[parentName] &&
			  formErrors[parentName][name[0]] &&
			  formErrors[parentName][name[0]][name[1]]
			: formErrors[name as string]);

	if (field.type === 'customElement' && !depFieldKey) {
		let customElementPermissions = HELPER_FUNCS.getSubPagePermissions(permissions, menuId as number);
		return (
			<Col {...colProps}>
				<InnerTableProvider value={{isInnerTable: true, visible}}>
					{field.component &&
						field.component({
							selectedRowId,
							disabled,
							mainModalVisible: visible,
							updateTable,
							formFromBack,
							form,
							data,
							permissions:
								/* TP-1193 Task First Method with hidding buttons */ visible === 'VIEW'
									? {post: 0, patch: 0, remove: 0, get: 1}
									: customElementPermissions
						})}
				</InnerTableProvider>
			</Col>
		);
	}
	return depFieldKey ? (
		<Form.Item
			noStyle
			shouldUpdate={(prevValues, currentValues) => {
				const depFieldKeyArr = Array.isArray(depFieldKey) ? depFieldKey : [depFieldKey];

				let update = false;
				if (parentName) {
					depFieldKeyArr.forEach(key => {
						if (
							(prevValues[parentName][index] && prevValues[parentName][index][key]) !==
							(currentValues[parentName][index] && currentValues[parentName][index][key])
						) {
							update = true;
						}
					});
				} else {
					depFieldKeyArr.forEach(key => {
						if (prevValues[key] !== currentValues[key]) update = true;
					});
				}

				return update;
			}}>
			{({getFieldValue}: FormInstance) => {
				let showField = false;
				let isFieldRequired = isRequired;
				let dependencies = undefined;

				let depFieldValue = '';
				let depFieldValueArr = {} as any;
				if (Array.isArray(depFieldKey)) {
					const depStatus = depFieldKey.every((key, i) => {
						const fieldValue = parentName
							? getFieldValue([parentName, index, key])
							: getFieldValue(key);

						if (fetchOnDepValue === true && fieldValue) {
							depFieldValueArr[key] = fieldValue;
						} else if (fetchOnDepValue === key && fieldValue) {
							depFieldValue = fieldValue;
						}

						if (
							Array.isArray(isRequired) &&
							Array.isArray(isRequiredDep) &&
							!!isRequiredDep.length
						) {
							const values = isRequired.reduce((unique: boolean[], el: any, idx: number) => {
								if (depFieldValueArr[isRequiredDep[idx]])
									unique.push(el(depFieldValueArr[isRequiredDep[idx]]));
								return unique;
							}, []);

							isFieldRequired = isRequiredAll
								? values.every(val => val)
								: values.includes(true);
							dependencies = parentName ? [[parentName, index, key]] : [key];
						}

						if (typeof isRequired === 'function' && isRequiredDep === key) {
							isFieldRequired = isRequired(fieldValue, getFieldValue);
							dependencies = parentName ? [[parentName, index, key]] : [key];
						}

						return (
							fieldValue === (Array.isArray(depValue) && depValue[i]) ||
							(Array.isArray(depValue) &&
								typeof depValue[i] === 'function' &&
								(depValue[i] as Function)(fieldValue)) ||
							(Array.isArray(fieldValue) &&
								fieldValue.some(
									(val: any) => val === (Array.isArray(depValue) && depValue[i])
								))
						);
					});

					if (depStatus) showField = true;
				} else {
					const fieldValue = parentName
						? getFieldValue([parentName, index, depFieldKey])
						: getFieldValue(depFieldKey);

					if (fetchOnDepValue && fieldValue) {
						depFieldValue = fieldValue;
					}

					if (typeof isRequired === 'function') {
						isFieldRequired = isRequired(fieldValue, getFieldValue);
						dependencies = parentName ? [[parentName, index, depFieldKey]] : [depFieldKey];
					}

					if (
						(fetchOnDepValue && fieldValue && fieldValue?.length !== 0) ||
						(typeof depValue === 'function' && depValue(fieldValue)) ||
						(depValue && fieldValue === depValue) ||
						(Array.isArray(fieldValue) && fieldValue.some((val: any) => val === depValue))
					) {
						showField = true;
					}
				}
				const fieldOptionValue =
					parentName && depOptionsKey
						? getFieldValue([parentName, index, depOptionsKey])
						: depOptionsKey && getFieldValue(depOptionsKey);

				if (field.type === 'customElement' && showField) {
					return (
						<Col {...colProps}>
							<InnerTableProvider value={{isInnerTable: true, visible}}>
								{field.component &&
									field.component(
										Object.values(depFieldValueArr).length
											? depFieldValueArr
											: depFieldValue,
										// @ts-ignore
										selectedRowId,
										disabled
									)}
							</InnerTableProvider>
						</Col>
					);
				}

				const selectAditionalProp =
					field.type === 'select'
						? {
								fetchId: depFieldValue,
								fetchParams: depFieldValueArr,
								fieldValue: depOptionsKey ? fieldOptionValue : undefined,
								depOptions: depOptions ? depOptions : undefined,
								depFieldKey
						  }
						: {};

				return showField || disabledOnDep ? (
					<Col {...colProps}>
						<Form.Item
							label={newLabel}
							name={name}
							rules={calcFieldRules(isFieldRequired as boolean, field.rules)}
							help={helpStatus}
							validateStatus={!!helpStatus ? 'error' : undefined}
							dependencies={dependencies}
							style={{marginBottom: field.type === 'checkbox' ? 0 : 24}}
							{...newFormItems}>
							<CaseInput
								getFieldValue={getFieldValue}
								disabled={disabled || (!showField && disabledOnDep)}
								{...props}
								{...selectAditionalProp}
							/>
						</Form.Item>
					</Col>
				) : null;
			}}
		</Form.Item>
	) : (
		<Col {...colProps}>
			<Form.Item
				label={newLabel}
				name={name}
				rules={calcFieldRules(isRequired as boolean, field.rules)}
				help={helpStatus}
				validateStatus={!!helpStatus ? 'error' : undefined}
				style={{marginBottom: field.type === 'checkbox' ? 0 : 24}}
				{...newFormItems}>
				<CaseInput disabled={disabled} {...props} className={field.className || null} />
			</Form.Item>
		</Col>
	);
};

const FormGroup = ({
	tabFields,
	formErrors,
	visible,
	formInitialValues,
	selectedRowId,
	form,
	formFromBack,
	formItemCustomInfo,
	updateTable,
	forceSetStateForCRUDFormItem,
	permissions,
	menuId
}: {
	tabFields: ModalFormFieldTypes[];
	formErrors: any;
	visible?: 'ADD' | 'EDIT' | 'VIEW';
	formInitialValues?: any;
	selectedRowId?: number;
	form?: FormInstance;
	formFromBack?: any;
	formItemCustomInfo?: any;
	updateTable?: () => void;
	forceSetStateForCRUDFormItem?: (value: any) => void;
	permissions?: any;
	menuId?: number;
}) => {
	return (
		<Row gutter={16}>
			{tabFields.map((field, i) => {
				const {
					name,
					colProps = defaultColProps,
					visibility = ['ADD', 'EDIT', 'VIEW'],
					groupTitle,
					minimalItem = 0,
					disabledAt,
					disableDynamicActions,
					disableDivider,
					isDisabled
				} = field;

				if (visible && !visibility.includes(visible)) return null;
				if (field.type === 'formGroup') {
					let initialFieldsLenght = null as any;
					const firstField = useRef(false);

					return (
						<Form.List key={i} name={name as string}>
							{(fields, {add, remove}) => (
								<>
									{!disableDynamicActions && visible !== 'VIEW' && (
										<Col xs={24}>
											<Form.Item label={field.label}>
												<Button
													type="dashed"
													onClick={() => {
														if (initialFieldsLenght === null)
															firstField.current = true;
														add();
													}}
													block
													icon={<PlusOutlined />}>
													{t('time')}
													{/* {field.label} */}
												</Button>
											</Form.Item>
										</Col>
									)}
									{fields.map(({key, name: fieldName}, index) => {
										if (initialFieldsLenght === null && !firstField.current)
											initialFieldsLenght = fields.length;

										const newLabel =
											formInitialValues &&
											formInitialValues[name as string] &&
											formInitialValues[name as string][index]?.label;

										return (
											<Col key={key} {...colProps} style={{display: 'flex'}}>
												<Row
													gutter={16}
													align="middle"
													style={{width: '100%', maxWidth: 400}}>
													{field.inputs?.map((input, i) => {
														const {
															visibility = ['ADD', 'EDIT', 'VIEW'],
															disabledAt,
															isDisabled
														} = input;

														if (visible && !visibility?.includes(visible))
															return null;
														return (
															<FormItem
																key={i}
																name={[fieldName, input.name as string]}
																field={input}
																formErrors={formErrors}
																parentName={name}
																index={index}
																form={form}
																dynemicLabel={newLabel}
																forceSetStateForCRUDFormItem={
																	forceSetStateForCRUDFormItem
																}
																disabled={
																	visible === 'VIEW' ||
																	(initialFieldsLenght > index &&
																		!!(
																			visible &&
																			disabledAt?.length &&
																			disabledAt.includes(visible)
																		)) ||
																	(isDisabled &&
																		isDisabled(
																			form?.getFieldValue(
																				name as string
																			)[index],
																			formFromBack
																		))
																}
															/>
														);
													})}
												</Row>
												<Row gutter={16} align="middle">
													{!!groupTitle && (
														<FormTitle
															label={groupTitle + ' ' + (index + 1)}
															colProps={{xs: 18}}
														/>
													)}

													{!disableDynamicActions &&
														minimalItem < fields.length &&
														visible !== 'VIEW' && (
															<Col xs={6}>
																<Button
																	className="form-group-delete"
																	onClick={() => {
																		if (initialFieldsLenght > index)
																			initialFieldsLenght -= 1;
																		remove(fieldName);
																	}}
																	icon={<Trash size={18} weight="fill" />}
																/>
															</Col>
														)}
												</Row>
												{!disableDivider && index !== fields.length - 1 && (
													<Row gutter={16} align="middle">
														<Col xs={24}>
															<Divider style={{margin: '0 0 15px'}} />
														</Col>
													</Row>
												)}
											</Col>
										);
									})}
								</>
							)}
						</Form.List>
					);
				}

				return (
					<FormItem
						key={i}
						name={name}
						field={field}
						formErrors={formErrors}
						formFromBack={formFromBack}
						menuId={menuId}
						permissions={permissions}
						form={form}
						disabled={
							!!(
								visible === 'VIEW' ||
								(visible && disabledAt?.length && disabledAt.includes(visible)) ||
								(isDisabled && isDisabled(form?.getFieldValue(name as string), formFromBack))
							)
						}
						selectedRowId={selectedRowId}
						visible={visible}
						formItemCustomInfo={formItemCustomInfo}
						updateTable={updateTable}
						forceSetStateForCRUDFormItem={forceSetStateForCRUDFormItem}
					/>
				);
			})}
		</Row>
	);
};

export default FormGroup;
