import {Button, Form} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import {TranslationFuncType} from 'components/CRUDTable/types';
import FormGroup from 'components/formItems/FormGroup';
import {AuthContext} from 'context/Auth.context';
import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import services from 'services';
import {loginFields} from './fields/login.form';
import {loginByEmailFields} from './fields/loginByEmailFields.form';
import './styles.less';
import {otpConfirmationSignInByEmail} from './fields/otpConfirmation.form';
import {resetPasswordFields} from './fields/resetPassword.form';
import {validateMessages} from 'components/CRUDTable/components/ModalForm';
import Toast from 'components/Toast';

interface InputErrorsTypes {
	email: string;
	password: string;
}

interface UserTypes {
	isSubmitting: boolean;
	inputErrors: InputErrorsTypes | null;
}

const getFields = (
	t: TranslationFuncType,
	fieldType: 'LOGIN' | 'LOGIN_BY_EMAIL' | 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL' | 'SET_PASSWORD',
	setFieldFunc: (
		fieldType: 'LOGIN' | 'LOGIN_BY_EMAIL' | 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL' | 'SET_PASSWORD'
	) => void
) => {
	switch (fieldType) {
		case 'LOGIN':
			return loginFields(t, setFieldFunc);
		case 'LOGIN_BY_EMAIL':
			return loginByEmailFields(t, setFieldFunc);
		case 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL':
			return otpConfirmationSignInByEmail(t, setFieldFunc);
		case 'SET_PASSWORD':
			return resetPasswordFields(t, setFieldFunc);
		default:
			return loginFields(t, setFieldFunc);
	}
};

const Login = () => {
	const {t} = useTranslation();
	const [user, setUser] = useState<UserTypes>({
		isSubmitting: false,
		inputErrors: null
	});
	const {dispatch} = useContext(AuthContext);
	const [emailForActions, setEmailForActions] = useState('');
	const navigate = useNavigate();
	const [form] = useForm();
	const [formErrors, setFormErrors] = useState([]);
	const [loginFields, setLoginFields] = useState<
		'LOGIN' | 'LOGIN_BY_EMAIL' | 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL' | 'SET_PASSWORD'
	>('LOGIN');

	const onFinish = (values: any) => {
		setUser({
			isSubmitting: true,
			inputErrors: null
		});
		switch (loginFields) {
			case 'LOGIN':
				services.AUTH.login(values)
					.then(res => {
						dispatch({
							type: 'LOGIN',
							payload: res.data
						});

						navigate('/', {replace: true});
					})
					.catch(err => {
						setUser({
							isSubmitting: false,
							inputErrors: t(`messages.code.${err.code}`)
						});
						Toast.error(t(`messages.code.${err.code}`));
					});
				break;
			case 'LOGIN_BY_EMAIL':
				services.AUTH.logInWithEmail(values)
					.then(res => {
						setEmailForActions(values.email);
						// dispatch({
						// 	type: 'LOGIN',
						// 	payload: res.data
						// });
						// navigate('/', {replace: true});
						setLoginFields('OTP_CONFIRMATION_SIGN_IN_BY_EMAIL');
					})
					.catch(err => {
						setUser({
							isSubmitting: false,
							inputErrors: t(`messages.code.${err.code}`)
						});
						Toast.error(t(`messages.code.${err.code}`));
					})
					.finally(() => {
						setUser({
							isSubmitting: false,
							inputErrors: null
						});
					});
				break;
			case 'OTP_CONFIRMATION_SIGN_IN_BY_EMAIL':
				services.AUTH.signInWithEmailOtp({email: emailForActions, otp: values.otp})
					.then(res => {
						localStorage.setItem('token', res.data.token);
						setLoginFields('SET_PASSWORD');
						if (res.data.require === true) {
							setLoginFields('SET_PASSWORD');
						} else {
							localStorage.setItem('token', JSON.stringify(res.data.token).replace(/"/g, ''));
							dispatch({
								type: 'LOGIN',
								payload: res.data
							});
							navigate('/', {replace: true});
						}
						setUser({
							isSubmitting: false,
							inputErrors: null
						});

						// navigate('/', {replace: true});
					})
					.catch(err => {
						const seconds =
							err.statusCode === 429 && err.code === 34 && err?.message?.match(/(\d+)/)[0];

						Toast.error(
							err.statusCode === 429 && err.code === 34
								? t(`messages.code.${err.code}`, {seconds})
								: t(`messages.code.${err.code}`)
						);

						setUser({
							isSubmitting: false,
							inputErrors: t(`messages.code.${err.code}`)
						});
					});
				// .finally(() => {
				// 	setUser({
				// 		isSubmitting: false,
				// 		inputErrors: null
				// 	});
				// });
				break;
			case 'SET_PASSWORD':
				const newValues = {
					...values,
					token: localStorage.getItem('token')
				};
				services.AUTH.setPassword(newValues)

					.then(res => {
						localStorage.setItem('token', res.data.token);

						dispatch({
							type: 'LOGIN',
							payload: {...res.data, token: localStorage.getItem('token')}
						});
						navigate('/', {replace: true});
					})
					.catch(err => {
						setUser({
							isSubmitting: false,
							inputErrors: t(`messages.code.${err.code}`)
						});
						Toast.error(t(`messages.code.${err.code}`));
						const error = err.errorFields?.[0]?.name?.[0];
						if (error) {
							const errorField = document.querySelector(`[name="${error}"]`) as HTMLElement;
							if (errorField) {
								errorField.scrollIntoView({
									behavior: 'smooth',
									block: 'center',
									inline: 'center'
								});
							}
						}
					});
				break;
			default:
				console.log('error');
		}
	};

	return (
		<div className="regLayout">
			<div className="regLayout__header">
				<div className="regLayout__header-logo">
					{/* <img src={UFAR_Logo_whit} alt="UFAR_Logo_white" width={168.57} /> */}
				</div>
				<div className="regLayout__header-menu"></div>
			</div>
			<div className="regLayout__main">
				<div className="regLayout__main_formContainer">
					<div className="regLayout__main_formContainer_title">
						<span>{t('signInTitle')}</span>
						<div id="divider" />
					</div>
					<Form
						validateTrigger="onBlur"
						className="regLayout__main_formContainer_form"
						layout="vertical"
						onFinish={onFinish}
						validateMessages={validateMessages(t)}
						autoComplete="off">
						<div style={{marginBottom: 10}}>
							<FormGroup
								tabFields={getFields(t, loginFields, setLoginFields)}
								form={form}
								formErrors={formErrors}
							/>
						</div>

						<Form.Item style={{textAlign: 'center'}}>
							<Button
								className="regLayout__main_formContainer_form_button"
								type="primary"
								htmlType="submit"
								loading={user.isSubmitting}>
								{t('signInBtn')}
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>

			<div className="regLayout__footer">
				© {t('footerText', {date: new Date().getFullYear()})}
				<a href="https://smartsoft.am/" style={{color: '#FFF', padding: '0 7px'}}>
					SMARTSOFT
				</a>
				{t('footerFrom')}
			</div>
		</div>
	);
};

export default Login;
