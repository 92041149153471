import {Button, Form, Modal, Steps, Tabs, Tooltip} from 'antd';
import {useWatch} from 'antd/lib/form/Form';
import {NamePath} from 'antd/lib/form/interface';
import FormGroup from 'components/formItems/FormGroup';
import _ from 'lodash';
import {CaretLeft, CaretRight} from 'phosphor-react';
import {FC, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ModalFormFieldTypes, ModalFormTypes, TranslationFuncType} from '../../types';
import './styles.less';
import Loading from 'components/FullScreenLoading';
import Toast from 'components/Toast';
import {PermissionContext} from 'context/Permission.context';

const {TabPane} = Tabs;
const {Step} = Steps;

export const validateMessages = (t: TranslationFuncType) => ({
	required: t('requiredInputError'),
	types: {
		email: t('emailInputError')
	}
});

interface ModalFormProps {
	showMap?: boolean;
	title: string;
	visible: 'ADD' | 'EDIT' | 'VIEW' | false;
	onCreate?: (values: any, index: number) => void;
	onCancel: () => void;
	cancelText?: string;
	okText?: string;
	selectedRowIds?: number[];
	selectedRowID?: number | null;
	fields?: ModalFormFieldTypes[];
	modalForm?: ModalFormTypes[];
	editModalType: 'TAB' | 'STEP';
	addModalType: 'TAB' | 'STEP';
	formErrors: any;
	modalError?: string | null;
	getFieldsValues?: (id: number) => Promise<any>;
	getFieldValues?: (data: any) => void;
	dialogSize?: 'sm' | 'md' | 'lg' | 'xs';
	formInitialValues?: any;
	stepFinish?: boolean;
	onFormValuesChange?: (
		resetFields: (fields?: NamePath[]) => void,
		changedValues: any,
		allValues: any,
		setFieldsValue: (values: any) => void
	) => void;
	loading: boolean;
	tableName?: string;
	formItemCustomInfo?: any;
	updateTable?: () => void;
	useWatchKeys?: string[];
	disableSubmitBtnOnAdd?: boolean;
	forceSetStateForCRUDFormItem?: (values: any) => void;
	// setErrors?: any;
	customFooter?: any;
	refreshData?: any;
	modalRefreshData?: boolean;
	setFormErrors?: any;
}

const ModalForm: FC<ModalFormProps> = ({
	title,
	visible,
	onCreate,
	onCancel,
	cancelText,
	okText,
	selectedRowIds,
	fields,
	modalForm,
	editModalType,
	addModalType,
	useWatchKeys,
	tableName,
	formErrors,
	getFieldsValues,
	getFieldValues,
	dialogSize = 'lg',
	modalError,
	formInitialValues,
	stepFinish,
	onFormValuesChange,
	loading,
	formItemCustomInfo,
	updateTable,
	selectedRowID,
	disableSubmitBtnOnAdd,
	forceSetStateForCRUDFormItem,
	showMap,
	refreshData,
	modalRefreshData,
	setFormErrors
}) => {
	const [current, setCurrent] = useState(0);
	const [formValues, setFormValues] = useState({});
	const [formDataByID, setFormDataByID] = useState({});
	const [disableSubmit, setDisableSubmit] = useState(true);
	const [loadingFlag, setLoadingFlag] = useState(false);
	const [form] = Form.useForm();

	const watchKeys =
		(useWatchKeys &&
			useWatchKeys.length &&
			useWatchKeys.map(key => {
				const watchKey = useWatch(key, form);
				return [key, watchKey];
			})) ||
		[];

	const {t} = useTranslation();
	const {state: permissionsState} = useContext(PermissionContext);
	const visibleModalForms =
		visible &&
		modalForm?.filter(
			form =>
				visible === 'VIEW' ||
				(form.visibility?.includes(visible) &&
					permissionsState.permissions.length &&
					(permissionsState.permissions.find((elem: any) => elem.menuId === form.menuId)?.get ===
						1 ||
						form.menuId === 0))
		);

	const isStepper =
		modalForm &&
		((visible === 'ADD' && addModalType === 'STEP') || (visible === 'EDIT' && editModalType === 'STEP'));
	const isTab =
		modalForm &&
		((visible === 'EDIT' && editModalType === 'TAB') ||
			(visible === 'ADD' && addModalType === 'TAB') ||
			visible === 'VIEW');

	const prev = () => {
		setCurrent(current - 1);
	};

	const next = () => {
		form.validateFields().then(values => {
			setFormValues(prev => ({...prev, ...{...values}}));
			setCurrent(current + 1);
		});
	};

	const handleModalClose = () => {
		onCancel();
		form.resetFields();
		setCurrent(0);
		setFormDataByID({});
	};

	const onTabChange = (activeKey: string) => {
		if (visible === 'EDIT') setDisableSubmit(true);
		setCurrent(+activeKey);
	};

	const onStepChange = (activeKey: number) => {
		setCurrent(activeKey);
	};

	const onValuesChange = (changedValues: any, allValues: any) => {
		if (visible === 'EDIT') setDisableSubmit(false);
		setFormErrors && setFormErrors({});
		onFormValuesChange &&
			onFormValuesChange(form.resetFields, changedValues, allValues, form.setFieldsValue);

		if (stepFinish) {
			setFormValues(prev => {
				const changedKey = Object.keys(changedValues)[0];
				if (
					changedKey === 'education' ||
					changedKey === 'admissionFiles' ||
					changedKey === 'universityExpectations'
				) {
					return {...prev, ...allValues};
				}

				return {...prev, ...changedValues};
			});
		}
	};

	const onSubmit = () => {
		form.validateFields()
			.then(values => {
				const newValues = _.cloneDeep(values);
				for (const key in newValues) {
					if (
						Object.prototype.hasOwnProperty.call(newValues, key) &&
						typeof newValues[key] === 'string'
					) {
						newValues[key] = newValues[key].trim();
					}

					if (values[key] !== undefined && values[key] !== '') {
						newValues[key] = values[key];
					} else {
						newValues[key] = null;
					}

					if (
						Object.prototype.hasOwnProperty.call(newValues, key) &&
						newValues[key] === undefined
					) {
						newValues[key] = null;
					}
				}

				if (isStepper) {
					const newForm = {...formValues, ...{...newValues}};
					onCreate && onCreate(newForm, current);
				} else {
					onCreate && onCreate(newValues, current);
				}
				if (localStorage.getItem('watchKeys')) {
					localStorage.removeItem('watchKeys');
				}

				setFormDataByID({});
			})
			.catch(err => {
				const error = err.errorFields?.[0]?.name?.[0];

				if (error) {
					const errorField = document.querySelector(`[name="${error}"]`) as HTMLElement;
					errorField.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
				}
			});
	};

	useEffect(() => {
		if (visible === false) {
			form.resetFields();
			setCurrent(0);
		} else if (visible === 'ADD') {
			setDisableSubmit(false);
			setFormDataByID({});
		} else if (visible === 'EDIT') {
			if (tableName === 'Schedules/Stops') {
				setDisableSubmit(false);
			} else {
				setDisableSubmit(true);
			}
		}
	}, [visible]);

	useEffect(() => {
		if (
			(visible === 'EDIT' || visible === 'VIEW') &&
			getFieldsValues &&
			selectedRowIds?.length === 1 &&
			!!!Object.keys(formDataByID).length
		) {
			getFieldsValues(selectedRowIds[0]).then(res => {
				const formData = res.data.data || res.data;
				getFieldValues && getFieldValues(formData);
				setFormDataByID(formData);
				for (let key in formInitialValues) {
					formData[key] =
						formData[key] === null || (Array.isArray(formData[key]) && formData[key].length === 0)
							? formInitialValues[key]
							: formData[key];
				}
				form.setFieldsValue(formData);
			});
		} else if (
			(visible === 'EDIT' || visible === 'VIEW') &&
			getFieldsValues &&
			selectedRowID &&
			!!!Object.keys(formDataByID).length
		) {
			getFieldsValues(selectedRowID).then(res => {
				const formData = res.data.data || res.data;
				setFormDataByID(formData);
				for (let key in formInitialValues) {
					formData[key] =
						formData[key] === null || (Array.isArray(formData[key]) && formData[key].length === 0)
							? formInitialValues[key]
							: formData[key];
				}
				form.setFieldsValue(formData);
			});
		}
	}, [selectedRowIds, visible, current]);

	useEffect(() => {
		if (formInitialValues) {
			form.setFieldsValue(formInitialValues);
		}
	}, [formInitialValues]);

	useEffect(() => {
		if (visible === 'ADD' && tableName === 'Curriculum/Faculties/Profession') {
			const disable = Object.values(form.getFieldsValue()).every((el: any) => !el);
			setDisableSubmit(disable);
		}
		if (watchKeys.some(([key, value]) => value === undefined)) return;
		localStorage.setItem('watchKeys', JSON.stringify(Object.fromEntries(watchKeys || [])));
	}, [watchKeys]);

	const customRefreshData = () => {
		setLoadingFlag(true);
		refreshData()
			.then((res: any) => {
				Toast.success(t(`messages.code.${res.code}`));
				const formData = res.data.data || res.data;
				setFormDataByID(formData);
				for (let key in formInitialValues) {
					formData[key] =
						formData[key] === null || (Array.isArray(formData[key]) && formData[key].length === 0)
							? formInitialValues[key]
							: formData[key];
				}
				form.setFieldsValue(formData);
				setLoadingFlag(false);
			})
			.catch(() => {
				setLoadingFlag(false);
			});
	};

	const customFooter = (
		<div onClick={customRefreshData} style={{cursor: 'pointer'}}>
			<Button style={{borderColor: '#61a599', height: 40, borderRadius: 10}}>{t('refreshData')}</Button>
		</div>
	);
	return visible ? (
		<Modal
			visible={!!visible}
			className="crud-modal"
			title={title}
			zIndex={1000}
			onCancel={loading ? undefined : handleModalClose}
			maskClosable={false}
			style={{top: 20}}
			width={
				dialogSize === 'lg' ? '100%' : dialogSize === 'md' ? 1400 : dialogSize === 'sm' ? 800 : 500
			}
			bodyStyle={{
				minHeight: dialogSize === 'lg' ? 'calc(100vh - 179px)' : dialogSize === 'sm' ? 180 : ''
			}}
			footer={[
				<div key="1" style={{display: 'flex'}}>
					<Button
						className="main-btn"
						key="cancel"
						size="large"
						disabled={loading}
						onClick={handleModalClose}>
						{cancelText || t('cancel')}
					</Button>
					{visible !== 'ADD' && modalRefreshData ? customFooter : <div></div>}
					<div key="prev">
						{isStepper && !!visibleModalForms && current > 0 && (
							<Button className="main-btn" size="large" onClick={prev} disabled={loading}>
								<CaretLeft size={14} weight="fill" style={{marginRight: 5}} /> {t('prev')}
							</Button>
						)}
					</div>
					<div key="next">
						{isStepper && !!visibleModalForms && current < visibleModalForms.length - 1 && (
							<Button
								className="main-btn"
								size="large"
								type="primary"
								onClick={next}
								disabled={loading}>
								{t('next')} <CaretRight size={14} weight="fill" style={{marginLeft: 5}} />
							</Button>
						)}
					</div>
					<div key="submit">
						{visible !== 'VIEW' &&
							(fields ||
								!isStepper ||
								(!!visibleModalForms && current === visibleModalForms.length - 1)) &&
							onCreate && (
								<Button
									className="main-btn"
									size="large"
									type="primary"
									onClick={form.submit}
									disabled={disableSubmit || (visible === 'ADD' && disableSubmitBtnOnAdd)}
									loading={loading}>
									{okText || t('save')}
								</Button>
							)}
					</div>
				</div>
			]}>
			{loadingFlag && <Loading />}
			<Form
				form={form}
				style={{top: 20, opacity: loadingFlag ? 0.2 : 1}}
				layout="vertical"
				validateTrigger="onBlur"
				validateMessages={validateMessages(t)}
				initialValues={formInitialValues}
				onValuesChange={onValuesChange}
				onFinish={onSubmit}>
				{!!fields && (
					<FormGroup
						tabFields={fields}
						formErrors={formErrors}
						formFromBack={formDataByID}
						visible={visible as 'ADD' | 'EDIT' | 'VIEW'}
						selectedRowId={selectedRowIds && selectedRowIds[0]}
						form={form}
						formItemCustomInfo={formItemCustomInfo}
						updateTable={updateTable}
						forceSetStateForCRUDFormItem={forceSetStateForCRUDFormItem}
					/>
				)}
				{isStepper && !!visibleModalForms && (
					<div className="form-steps">
						<Steps
							current={current}
							progressDot
							direction="vertical"
							size="small"
							onChange={stepFinish ? onStepChange : undefined}>
							{visibleModalForms.map((item, i) => (
								<Step
									key={i}
									title={item.name}
									status={item.errorStatus ? 'error' : stepFinish ? 'finish' : undefined}
								/>
							))}
						</Steps>
						<div className="steps-content">
							<FormGroup
								permissions={permissionsState}
								tabFields={visibleModalForms[current].fields}
								formErrors={formErrors}
								visible={visible}
								formInitialValues={formInitialValues}
								formFromBack={formDataByID}
								selectedRowId={selectedRowIds && selectedRowIds[0]}
								form={form}
								formItemCustomInfo={formItemCustomInfo}
								updateTable={updateTable}
								forceSetStateForCRUDFormItem={forceSetStateForCRUDFormItem}
							/>
						</div>
					</div>
				)}
				{isTab && !!visibleModalForms && (
					<Tabs
						tabPosition="left"
						destroyInactiveTabPane
						activeKey={current.toString()}
						onChange={onTabChange}>
						{visibleModalForms.map((tab, i) => {
							return (
								<TabPane
									tab={
										<Tooltip placement="top" title={tab.tooltip}>
											{tab.name}
										</Tooltip>
									}
									key={i}
									disabled={tab.disabled}>
									<FormGroup
										menuId={tab.menuId}
										permissions={permissionsState.permissions}
										tabFields={tab.fields}
										formErrors={formErrors}
										visible={visible}
										formInitialValues={formInitialValues}
										selectedRowId={selectedRowIds && selectedRowIds[0]}
										form={form}
										formFromBack={formDataByID}
										formItemCustomInfo={formItemCustomInfo}
										updateTable={updateTable}
										forceSetStateForCRUDFormItem={forceSetStateForCRUDFormItem}
									/>
								</TabPane>
							);
						})}
					</Tabs>
				)}
			</Form>

			{modalError && <div className="modalErr">{modalError ? modalError : null}</div>}
		</Modal>
	) : (
		<></>
	);
};

export default ModalForm;
