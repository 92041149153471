import FullScreenLoading from 'components/FullScreenLoading';
import AuthProvider from 'context/Auth.context';
import NotificationsProvider from 'context/Notifications.context';
import moment from 'moment';
import {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import 'moment/locale/hy-am';
import 'moment/locale/en-gb';
import 'moment/locale/ru';
import 'moment/locale/fr';

moment.locale('hy-am', {
	week: {
		dow: 1
	}
});

moment.locale('en-gb', {
	week: {
		dow: 1
	}
});

moment.locale('ru', {
	week: {
		dow: 1
	}
});

moment.locale('fr', {
	week: {
		dow: 1
	}
});

import App from './App';
import './index.css';
import './utils/i18n';
import SettingsProvider from 'context/Settings.context';
import PermissionProvider from 'context/Permission.context';

ReactDOM.render(
	<Suspense fallback={<FullScreenLoading />}>
		<AuthProvider>
			<PermissionProvider>
				<SettingsProvider>
					<NotificationsProvider>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</NotificationsProvider>
				</SettingsProvider>
			</PermissionProvider>
		</AuthProvider>
	</Suspense>,
	document.getElementById('root')
);

reportWebVitals();
