import {t} from 'i18next';

export const settingsPagesIds = [23, 22, 28, 29, 30, 32, 33, 34, 35, 36, 37, 39];

export const dictionaries = [
	{key: 'cities', label: 'communities'},
	{key: 'contact-types', label: 'contactTypes'},
	{key: 'complaints-types', label: 'complaintsTypes'},
	{key: 'complaints-statuses', label: 'complaintsStatuses'},
	{key: 'company-legal-forms', label: 'organizationType'},
	{key: 'contract-statuses', label: 'contractStatuses'},
	{key: 'genders', label: 'genders'},
	{key: 'route-types', label: 'routeTypes'},
	{key: 'route-statuses', label: 'routeStatuses'},
	{key: 'route-modes', label: 'routeModes'},
	{key: 'route-working-modes', label: 'routeWorkingModes'},
	{key: 'holder-types', label: 'holderTypes'},
	{key: 'holder-kinds', label: 'holderKinds'},
	{key: 'holder-statuses', label: 'holderStatuses'},
	{key: 'holder-storage-statuses', label: 'holderStorageStatuses'},
	{key: 'faq-categories', label: 'FAQTypes'},
	{key: 'driver-statuses', label: 'driverStatuses'},
	{key: 'tariff-types', label: 'tariffTypes'},
	{key: 'tariff-holder-types', label: 'ticketHolderTypes'},
	{key: 'transaction-types', label: 'transactionsTypes'},
	{key: 'transaction-statuses', label: 'transactionStatuses'},
	{key: 'transaction-payment-types', label: 'transactionPaymentTypes'},
	{key: 'transaction-enterprise-types', label: 'transactionEnterpriseTypes'},
	{key: 'transport-types', label: 'busTypes'},
	{key: 'transport-statuses', label: 'transportStatuses'},
	{key: 'transport-categories', label: 'manufacturers'},
	{key: 'validator-usage-types', label: 'validatorUsageTypes'},
	{key: 'validator-statuses', label: 'validatorStatuses'},
	{key: 'transfer-statuses', label: 'transferStatuses'},
	{key: 'payment-providers', label: 'paymentProviders'},
	{key: 'payment-statuses', label: 'paymentStatuses'},
	{key: 'payment-types', label: 'paymentTypes'}
];

const inputGenerator = (visibleName: string, props?: any) => {
	let key = visibleName.split(' ').join('');
	key = key.slice(0, 1).toLowerCase() + key.slice(1);

	return {
		title: visibleName,
		label: visibleName,
		key,
		name: props?.key || key,
		type: props?.type || 'text',
		colProps: {
			xs: 24,
			lg: 24
		},
		isRequired: true,
		...props
	};
};

const name = inputGenerator('name', {
	title: 'title',
	label: 'title',
	// rules: [armenianRulesWithNumber(t)],
	key: 'hy'
});

const nameEn = inputGenerator('nameEn', {
	title: 'title',
	label: 'title',
	// rules: [latinRulesWithNumber(t)],
	key: 'en'
});
const id = inputGenerator('id', {width: 100, title: t('id'), label: t('id')});

const names = [name, nameEn];

export type dictStringTypes =
	| 'cities'
	| 'contact-types'
	| 'complaints-types'
	| 'complaints-statuses'
	| 'company-legal-forms'
	| 'contract-statuses'
	| 'genders'
	| 'route-types'
	| 'roles'
	| 'route-statuses'
	| 'route-modes'
	| 'route-working-modes'
	| 'holder-types'
	| 'holder-kinds'
	| 'holder-statuses'
	| 'holder-storage-statuses'
	| 'faq-categories'
	| 'driver-statuses'
	| 'tariff-types'
	| 'tariff-holder-types'
	| 'transaction-types'
	| 'transaction-statuses'
	| 'transaction-payment-types'
	| 'transaction-enterprise-types'
	| 'transport-types'
	| 'transport-statuses'
	| 'transport-categories'
	| 'validator-usage-types'
	| 'validator-statuses'
	| 'transfer-statuses'
	| 'payment-providers'
	| 'payment-statuses'
	| 'payment-types';

export const defColsForDicts: {[key in dictStringTypes]: any[]} = {
	cities: [id, ...names],
	'contact-types': [id, ...names],
	'complaints-types': [id, ...names],
	'complaints-statuses': [id, ...names],
	'company-legal-forms': [id, ...names],
	'contract-statuses': [id, ...names],
	genders: [id, ...names],
	'route-types': [id, ...names],
	'route-statuses': [id, ...names],
	'route-modes': [id, ...names],
	roles: [id, ...names],
	'route-working-modes': [id, ...names],
	'holder-types': [id, ...names],
	'holder-kinds': [id, ...names],
	'holder-statuses': [id, ...names],
	'holder-storage-statuses': [id, ...names],
	'faq-categories': [id, ...names],
	'driver-statuses': [id, ...names],
	'tariff-types': [id, ...names],
	'tariff-holder-types': [id, ...names],
	'transaction-types': [id, ...names],
	'transaction-statuses': [id, ...names],
	'transaction-payment-types': [id, ...names],
	'transaction-enterprise-types': [id, ...names],
	'transport-types': [id, ...names],
	'transport-statuses': [id, ...names],
	'transport-categories': [id, ...names],
	'validator-usage-types': [id, ...names],
	'validator-statuses': [id, ...names],
	'transfer-statuses': [id, ...names],
	'payment-providers': [id, ...names],
	'payment-statuses': [id, ...names],
	'payment-types': [id, ...names]
};
