import axiosClient from 'utils/axiosClient';
import api from 'api';

const getRoles = async (params?: any) => {
	const response = await axiosClient.get(api.ROLES.ROLES, {params});
	return response.data;
};

const getRoleById = async (params?: any, id?: any) => {
	const response = await axiosClient.get(api.ROLES.ROLES + `/${id}`, {params});
	return response.data;
};
const updateRoles = async (params?: any, id?: number) => {
	const response = await axiosClient.patch(api.ROLES.ROLES + `/${id}`, params);
	return response.data;
};
const createRoles = async (params?: any) => {
	const response = await axiosClient.post(api.ROLES.ROLES, params);
	return response.data;
};

const service = {
	getRoles,
	updateRoles,
	createRoles,
	getRoleById
};

export default service;
