import moment from 'moment';
import i18n from 'utils/i18n';

const columnLangKey = (val: Record<string, any>, record?: Record<string, any>, recordString?: string) => {
	if (!val) return '';
	return (
		val[i18n.language] ||
		(record && record[i18n.language]) ||
		(record && record[`companyName` + i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)]) ||
		(record &&
			recordString &&
			record[recordString + i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1)])
	);
};

const langKey = (key: string = '') => {
	return key + i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1);
};

const truncateText = (text: string, maxLength: number) => {
	if (text.length > maxLength) {
		return text.substring(0, maxLength - 3) + '...';
	}
	return text;
};

const mutatedateRangeKey = (state: Record<string, any> | string, key: string = 'createdAt') => {
	let newData: Record<string, any> = {};
	const isStateString = typeof state === 'string';

	if (isStateString) {
		newData[key] = state;
	} else {
		newData = {...state};
	}

	if (Array.isArray(newData[key]) && newData[key]?.length) {
		newData[key] = `${moment(moment(newData[key][0]).format('YYYY-MM-DD'))
			.startOf('day')
			.toISOString()}_${moment(moment(newData[key][1]).format('YYYY-MM-DD'))
			.endOf('day')
			.toISOString()}`;
	}
	return isStateString ? newData[key] : newData;
};

const formatDate = ({
	formatableDate,
	preFormat = 'DD-MM-YYYY HH:mm',
	format = 'YYYY-MM-DDTHH:mm:ss',
	dateRangeStartOf = 'minute',
	dateRangeEndOf = 'minute'
}: {
	formatableDate: string;
	preFormat?: string;
	format?: string;
	dateRangeStartOf?: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';
	dateRangeEndOf?: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';
}) => {
	return formatableDate
		? `${moment(formatableDate, preFormat).startOf(dateRangeStartOf).utc().format(format)}_${moment(
				formatableDate,
				preFormat
		  )
				.endOf(dateRangeEndOf)
				.utc()
				.format(format)}`
		: undefined;
};
const getBalanceString = (t: any, value: string) => {
	return `${value.slice(0, -3)}`;
};

const decodeJWT = (token: string) => {
	try {
		const [header, payload] = token.split('.'); // Split the token into parts

		// Decode header and payload (Base64URL decoding)
		const decodeBase64Url = (str: string) =>
			decodeURIComponent(
				atob(str.replace(/-/g, '+').replace(/_/g, '/'))
					.split('')
					.map(c => `%${c.charCodeAt(0).toString(16).padStart(2, '0')}`)
					.join('')
			);

		const decodedHeader = JSON.parse(decodeBase64Url(header));
		const decodedPayload = JSON.parse(decodeBase64Url(payload));

		return {header: decodedHeader, payload: decodedPayload};
	} catch (error) {
		console.error('Invalid JWT', error);
		return null;
	}
};

const getSubPagePermissions = (permissions: any, menuId: number) => {
	if (!Array.isArray(permissions) || menuId === 0) {
		return {post: 1, patch: 1, remove: 1};
	}
	let pagePermission: any = permissions.find((elem: any) => elem?.menuId == menuId);
	let {post, patch, get} = pagePermission;

	return {post, patch, remove: pagePermission.delete, get};
};
const HELPER_FUNCS = {
	columnLangKey,
	langKey,
	truncateText,
	mutatedateRangeKey,
	formatDate,
	getBalanceString,
	decodeJWT,
	getSubPagePermissions
};

export default HELPER_FUNCS;
