import {Select} from 'antd';
import _ from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import services from 'services';
import i18n from 'utils/i18n';

const {Option} = Select;

const SelectField = (props: any) => {
	const {
		value,
		dictionaryApiPath,
		dictionaryParams = undefined,
		depOptions,
		fieldValue,
		options: _options,
		customApiFetch = {
			path: '',
			labelKey: '',
			valueKey: '',
			notSendKeys: [],
			addFetchID: false,
			filter: ''
		},
		hiddenOptions,
		fetchId,
		fetchParams: _fetchParams,
		disabled,
		startWord,
		mode,
		onChange,
		selectFirstAsDefault,
		disabledOptions,
		...rest
	} = props;
	const {t} = useTranslation();
	const [options, setOptions] = useState(_options || []);
	const [defOptions, setDefOptions] = useState([]);
	const [fetchParams, setFetchParams] = useState({});
	const newValue = Array.isArray(value) ? value.map(el => el?.id || el) : value;

	const isAllSelected = useRef<boolean>(false);

	useEffect(() => {
		const newData = _.cloneDeep(_fetchParams || {});
		if (customApiFetch?.notSendKeys?.length) {
			customApiFetch?.notSendKeys.forEach((key: string) => {
				if (newData[key]) {
					delete newData[key];
				}
			});
		}

		if (!_.isEqual(fetchParams, newData)) {
			setFetchParams(newData);
		}
	}, [_fetchParams]);

	const fetchOptions = useCallback(async () => {
		if (customApiFetch.path) {
			const newPath =
				fetchId && customApiFetch?.addFetchID ? customApiFetch.path + fetchId : customApiFetch.path;
			const depLength = Array.isArray(props.depFieldKey)
				? customApiFetch?.notSendKeys?.length
					? props.depFieldKey.length - customApiFetch?.notSendKeys?.length
					: props.depFieldKey.length
				: customApiFetch?.notSendKeys?.length
				? 0
				: 1;
			const fetchLength = Object.values(fetchParams).reduce((retVal: number, val: any) => {
				if (Array.isArray(val) && !!!val.length) {
					retVal > 0 && retVal--;
				} else if (Array.isArray(val) && !!val.length) {
					retVal++;
				} else if (!!val) {
					retVal++;
				} else if (!!!val) {
					retVal > 0 && retVal--;
				}
				return retVal;
			}, 0);

			if (
				customApiFetch?.fetchAnyway ||
				depLength === fetchLength ||
				customApiFetch?.addFetchID ||
				(!props?.depFieldKey && !options.length)
			) {
				await services.SETTINGS.getByCustomPath(newPath, {...fetchParams, all: true})
					.then(async res => {
						let data = res.items || res;

						const filterRes =
							hiddenOptions?.length || typeof hiddenOptions === 'function'
								? data.filter((el: any) =>
										typeof hiddenOptions === 'function'
											? !hiddenOptions(data, value).includes(el.id)
											: !hiddenOptions.includes(el.id)
								  )
								: data;
						// if id not found in options starts
						let values = [];
						if (Array.isArray(value) && value.length) {
							values.push(
								...value
									.filter(
										(id: any) =>
											data.findIndex(
												(el: any) =>
													el[customApiFetch.valueKey] === id?.id ||
													el[customApiFetch.valueKey] === id
											) === -1
									)
									.map((el: any) => el?.id || el)
							);
						} else if (
							value &&
							!Array.isArray(value) &&
							data.findIndex((el: any) => el[customApiFetch.valueKey] === value) === -1
						) {
							values.push(value);
						}

						let newSurvay: any = [];
						if (values.length) {
							const changedPath = customApiFetch.path.includes('?')
								? customApiFetch.path.slice(0, customApiFetch.path.indexOf('?'))
								: customApiFetch.path;

							const key =
								customApiFetch.valueKey === 'id' ? 'id' : customApiFetch.valueKey + 's';
							newSurvay = await services.SETTINGS.getByCustomPath(changedPath, {
								[key]: values[0],
								all: true
							});
						}
						//end
						setOptions(() => {
							if (newSurvay?.items?.length) filterRes.push(...newSurvay.items);
							const optionsData = filterRes.map((opt: any) => ({
								label:
									typeof customApiFetch.labelKey === 'function'
										? customApiFetch.labelKey(opt)
										: opt[customApiFetch.labelKey],
								value:
									typeof customApiFetch.valueKey === 'function'
										? customApiFetch.valueKey(opt)
										: opt[customApiFetch.valueKey],
								hoverName: customApiFetch?.hoverName
									? typeof customApiFetch.hoverName === 'function'
										? customApiFetch.hoverName(opt)
										: opt[customApiFetch.hoverName]
									: '',
								status: opt?.status
							}));
							if (mode === 'multiple') {
								isAllSelected.current = optionsData?.length === newValue?.length;
							}
							return optionsData;
						});
					})
					.catch(console.log);
			}
		} else if (dictionaryApiPath && !!!options.length) {
			services.SETTINGS.fetchOptions(dictionaryApiPath, dictionaryParams).then(res => {
				const filterRes =
					hiddenOptions?.length || typeof hiddenOptions === 'function'
						? res.filter(el =>
								typeof hiddenOptions === 'function'
									? hiddenOptions(res, value).includes(el.id)
									: !hiddenOptions.includes(el.id)
						  )
						: res;

				setOptions(() => {
					let data = filterRes.map(opt => ({
						label: opt[i18n.language as 'hy' | 'en'],
						value: opt.id,
						status: opt?.status
					})) as any;
					if (mode === 'multiple') {
						isAllSelected.current = data?.length === newValue?.length;
					}

					if (depOptions) {
						data = depOptions(fieldValue, data);
					}

					return data;
				});
				depOptions &&
					setDefOptions(() => {
						const data = filterRes.map(opt => ({
							label: opt[i18n.language as 'hy' | 'en'],
							value: opt.id,
							status: opt?.status
						})) as any;

						if (mode === 'multiple') {
							isAllSelected.current = data?.length === newValue?.length;
						}

						return data;
					});
			});
		}
	}, [dictionaryApiPath, dictionaryParams, hiddenOptions, fetchId, fetchParams, customApiFetch, value]);

	useEffect(() => {
		fetchOptions();
	}, [fetchOptions]);

	const handleChange = (value: string | string[]) => {
		if (mode === 'multiple') {
			if (value.includes('all')) {
				if (isAllSelected.current) {
					isAllSelected.current = false;
					onChange([]);
				} else {
					isAllSelected.current = true;
					onChange(options.map((el: any) => el.value));
				}
			} else {
				isAllSelected.current = options.length === value.length;
				onChange(value);
			}
		} else {
			onChange(value);
		}
	};

	useEffect(() => {
		depOptions && setOptions(depOptions(fieldValue, defOptions));
	}, [fieldValue, depOptions]);

	useEffect(() => {
		if (selectFirstAsDefault && options.length && (value === undefined || value === null)) {
			// TO CHECK
			const newValue = mode === 'multiple' ? [options?.[0]?.value] : [options?.[0]?.value];
			handleChange(newValue);
		}
	}, [options]);

	return (
		<Select
			{...rest}
			disabled={disabled}
			showSearch
			optionFilterProp="children"
			filterOption={(input, option) => {
				return option?.children?.toString()?.toLowerCase()?.includes(input.toLowerCase());
			}}
			value={startWord ? `${startWord} ${newValue}` : newValue}
			mode={mode}
			getPopupContainer={trigger => {
				return (
					trigger?.parentNode?.parentElement?.parentElement ||
					trigger?.parentNode?.parentElement ||
					trigger?.parentNode ||
					trigger
				);
			}}
			onChange={handleChange}>
			{mode === 'multiple' && options.length && (
				<Option
					value="all"
					className={
						isAllSelected.current
							? 'ant-select-item-option-active ant-select-item-option-selected'
							: ''
					}>
					{t('selectAll')}
				</Option>
			)}
			{options.map((el: any) => (
				<Option
					key={el.value}
					value={el.value}
					title={el?.hoverName ? el.hoverName : el.label}
					disabled={
						el?.status === 0 || typeof disabledOptions === 'function'
							? disabledOptions(el, value).includes(el.value)
							: disabledOptions?.includes(el.value)
					}>
					{el.label}
				</Option>
			))}
		</Select>
	);
};

export default SelectField;
